import React from "react"
import Slider from "react-slick"

import { useStaticQuery, graphql } from "gatsby"

import BlockWrapper from "../technical/BlockWrapper"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const NewsCardFeedItem = ({ item }) => {
  let content
  if (item.content) {
    content = JSON.parse(item.content)
  }
  return (
    <Link className="relative block overflow-hidden group" link={item.link}>
      <div className="aspect-[2/3]">
        <Image
          className="object-cover w-full h-full"
          aspectRatio="2by3"
          image={content.image}
        />
      </div>
      <div className="absolute inset-0 transition-opacity duration-500 ease-in-out bg-black opacity-0 bg-opacity-30 group-hover:opacity-100"></div>
      <div className="absolute inset-x-0 bottom-0 transition-transform duration-500 ease-in-out transform translate-y-full group-hover:translate-y-0 py-2.5 px-grid">
        <p className="prose text-white line-clamp-5">
          {item.title}
          {item.text?.length > 0 ? ": " + item.text : ""}
        </p>
      </div>
    </Link>
  )
}

const NewsCardFeedBlock = ({ block }) => {
  // const data = useStaticQuery(graphql`
  //   query NewsFeedQuery {
  //     allStoryblokEntry(
  //       filter: { field_component: { eq: "news_page" } }
  //       limit: 100
  //     ) {
  //       edges {
  //         node {
  //           id
  //           field_kicker_string
  //           name
  //           field_teaser_string
  //           full_slug
  //           content
  //           fields {
  //             full_slug_prepared
  //             language
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  // let lang

  // if (typeof window !== "undefined") {
  //   lang = window.location.pathname.split("/")[1]
  // }

  // let newsFeed = data.allStoryblokEntry.edges.filter(
  //   (news) =>
  //     news.node.fields.language === lang &&
  //     news.node.field_kicker_string === block.category
  // )

  // newsFeed.splice(6)

  // const settings = {
  //   slidesToShow: 3,
  //   slidesToScroll: 3,
  //   dots: true,
  //   arrows: false,
  //   mobileFirst: true,
  //   infinite: false,
  //   appendDots: (dots) => (
  //     <div>
  //       <ul> {dots} </ul>
  //     </div>
  //   ),
  //   customPaging: (i) => <button page={i + 1}></button>,

  //   responsive: [
  //     {
  //       breakpoint: 672,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // }

  // return (
  //   <BlockWrapper block={block} showHeadline="false">
  //     <div className="flex flex-row flex-wrap -mx-grid">
  //       <div className="w-full lg:w-1/3 px-grid">
  //         <div className="flex flex-col justify-center h-full">
  //           <HeadlineEnhanced kicker={block.kicker} headline={block.headline} />
  //           <Richtext className="mt-8 mb-5 lg:mb-0" text={block.text} />
  //         </div>
  //       </div>
  //       <div className="w-full lg:w-2/3 px-grid">
  //         {typeof window !== "undefined" && window.storyblok?.inEditor && (
  //           <div
  //             className="py-2 mb-8 rounded px-grid"
  //             style={{ backgroundColor: "#09b3af" }}
  //           >
  //             <p className="font-medium prose text-white">
  //               <h3 style={{ color: "#ffffff" }}>Hinweis:</h3>
  //               <b>News-Feed:</b> Aus technischen Gründen sind die Daten des
  //               News-Feed in der <i>Vorschau</i> nicht verfügbar und können
  //               deshalb nicht dargestellt werden.
  //             </p>
  //           </div>
  //         )}
  //         {newsFeed?.length > 0 && (
  //           <Slider {...settings} className="-mx-2.5">
  //             {newsFeed.map((item, index) => (
  //               <div className="py-2.5 px-half-grid" key={index}>
  //                 <NewsCardFeedItem
  //                   item={{
  //                     link: item.node.fields.full_slug_prepared,
  //                     content: item.node.content,
  //                     title: item.node.name,
  //                     text: item.node.field_teaser_string,
  //                   }}
  //                 />
  //               </div>
  //             ))}
  //           </Slider>
  //         )}
  //       </div>
  //     </div>
  //   </BlockWrapper>
  // )

  return (
    <div>
      {typeof window !== "undefined" && window.storyblok?.inEditor && (
        <div
          className="py-2 mb-8 rounded px-grid"
          style={{ backgroundColor: "#09b3af" }}
        >
          <p className="font-medium prose text-white">
            <h3 style={{ color: "#ffffff" }}>Hinweis:</h3>
            <b>News-Feed:</b> Diese Webseite integriert aktuell kein
            News-Plugin.
          </p>
        </div>
      )}
    </div>
  )
}

export default NewsCardFeedBlock
